// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/GeneralContext.ts"
);
import.meta.hot.lastModified = "1717674383000";
}
// REMIX HMR END

import { createContext } from "react";
import { IGeneralContext } from "../types";


export const GeneralContext = createContext<{
  generalContextValue: IGeneralContext;
  // setGeneralContextValue: Dispatch<SetStateAction<IGeneralContext>>;
}>({
  generalContextValue: {
    isAuthenticated: false,
    name: "",
    baseApiUrl: "",
    authToken: "",
    userInfo: undefined,

  },
  // setGeneralContextValue: () => {},
});
